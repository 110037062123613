import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import MDXRenderer from 'gatsby-mdx/mdx-renderer';
import postStyles from '../components/post.module.css';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PrevNext from '../components/PrevNext';

const Header = () => {
  return (
    <div className="bg-orange-900 flex min-w-full">
      <div className="inline-block mx-10 w-1/4 flex justify-start ">
        <Link to="/" className=" leading-tight tracking-tight font-hairline mx-5 py-2 text-2xl text-orange-100">
          RyanBethel
        </Link>
      </div>
      <div className="inline-block w-2/3 mx-10 flex justify-end "></div>
    </div>
  );
};

const Post = ({ pageContext: { slug, prev, next }, data: { mdx: postNode } }) => {
  const post = postNode.frontmatter;

  return (
    <Layout customSEO>
      <Header />
      <div className="bg-orange-200">
        <div className="max-w-4xl mx-auto px-5 sm:px-2 md:px-5 lg:px-10 bg-orange-200">
          <SEO postPath={slug} postNode={postNode} article />
          <div className="py-10 prose lg:prose-xl">
            <h1 className="text-orange-900 text-2xl md:text-4xl font-bold">{post.title}</h1>
            <div>
              {post.date} &mdash;{' '}
              {post.categories.map((cat, i) => (
                <React.Fragment key={cat}>
                  {!!i && ', '}
                  {cat}
                </React.Fragment>
              ))}
            </div>
            {post.featuredImage && <Img fluid={post.featuredImage.childImageSharp.fluid} />}
            {/* <div className={postStyles.markdown}> */}
            <div>
              <MDXRenderer>{postNode.code.body}</MDXRenderer>
            </div>
            <hr className=" border-solid border-gray-900" />
            <PrevNext prev={prev} next={next} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
};

Post.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
};

export const postQuery = graphql`
  query postBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      frontmatter {
        title
        date(formatString: "MM/DD/YYYY")
        categories
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`;
